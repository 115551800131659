<template>
  <div :class="$style.home">
    <div :class="$style.left">
      <div :class="$style.leftFlow">
        <div :class="$style.head">
          <img :src="oss + '/manage/media/assets/statusbar_b.png'" />
        </div>
        <div :class="[$style.uchead, type === 'uc_head' ? $style.active : null]" @click="onChoose('uc_head')">
          <div
            v-if="data.uc_head.style === 'fillet'"
            :style="{ 'background-image': 'url( ' + data.uc_head.bg_image.cover + ')' }"
            :class="$style.fillet"
          >
            <p :class="$style.title">
              <a-badge :class="$style.i" count="2">
                <i class="ri-notification-line" />
              </a-badge>
              我的
            </p>
            <div :class="$style.info">
              <img :src="oss + '/manage/media/assets/avator_info.png'" />
              <div :class="$style.text">
                <p>100米以内</p>
                <p>182 **** 9537</p>
              </div>
            </div>
          </div>

          <div v-if="data.uc_head.style === 'card'" :class="$style.card">
            <p :class="$style.title">
              <a-badge :class="$style.i" count="2">
                <i class="ri-notification-line" />
              </a-badge>
              我的
            </p>

            <div :class="$style.content" :style="{ 'background-image': 'url( ' + data.uc_head.bg_image.cover + ')' }">
              <div :class="$style.info">
                <img :src="oss + '/manage/media/assets/avator_info.png'" alt="" />
                <div :class="$style.text">
                  <p>100米以内</p>
                  <p>182 **** 9537</p>
                </div>
              </div>
            </div>
          </div>

          <div v-if="data.uc_head.style === 'arc'" :class="$style.arc">
            <div :class="$style.radius">
              <img :src="data.uc_head.bg_image.cover" alt="" />
              <p :class="$style.title">
                <a-badge :class="$style.i" count="2">
                  <i class="ri-notification-line" />
                </a-badge>
                我的
              </p>
            </div>

            <div :class="$style.info">
              <img :src="oss + '/manage/media/assets/avator_info.png'" alt="" />
              <div :class="$style.text">
                <p>100米以内</p>
                <p>182 **** 9537</p>
              </div>
            </div>
          </div>
          <div :class="$style.editoractive" />
        </div>
        <div :class="[$style.ucdata, type === 'uc_data' ? $style.active : null]" @click="onChoose('uc_data')">
          <div :class="$style.box" :style="dataStyle">
            <div v-for="(item, index) in showData" :key="index" :class="$style.item">
              <p>{{ item.num }}</p>
              <p :style="dataTipStyle">{{ item.name }}</p>
            </div>
          </div>
          <div :class="$style.editoractive" />
        </div>
        <div :class="[$style.ucuser, type === 'uc_app' ? $style.active : null]" @click="onChoose('uc_app')">
          <div v-if="data.uc_app.show_style === 'list'" :class="$style.list">
            <div v-for="(item, index) in userData" :key="index" :class="$style.listItem">
              <img :src="item[data.uc_user.icon_style]" alt="" />
              <div :class="$style.text">
                <span>{{ item.name }}</span>
                <span :class="$style.btn">
                  <span>{{ item.tip || null }}</span>
                  <i class="ri-arrow-right-s-line" />
                </span>
              </div>
            </div>
          </div>
          <div v-if="data.uc_user.show_style === 'lattice'" :class="$style.boxs">
            <p v-if="data.uc_user.is_show_title" :class="$style.title">用户相关</p>
            <div :class="$style.lattice">
              <div v-for="(item, index) in userData" :key="index" :class="$style.latticeItem">
                <img :src="item[data.uc_user.icon_style]" alt="" />
                <p class="mt-4">{{ item.name }}</p>
              </div>
            </div>
          </div>
          <div :class="$style.editoractive" />
        </div>
        <div :class="[$style.ucuser, type === 'uc_user' ? $style.active : null]" @click="onChoose('uc_user')">
          <div v-if="data.uc_user.show_style === 'list'" :class="$style.list">
            <div v-for="(item, index) in userData" :key="index" :class="$style.listItem">
              <img :src="item[data.uc_user.icon_style]" alt="" />
              <div :class="$style.text">
                <span>{{ item.name }}</span>
                <span :class="$style.btn">
                  <span>{{ item.tip || null }}</span>
                  <i class="ri-arrow-right-s-line" />
                </span>
              </div>
            </div>
          </div>
          <div v-if="data.uc_user.show_style === 'lattice'" :class="$style.boxs">
            <p v-if="data.uc_user.is_show_title" :class="$style.title">用户相关</p>
            <div :class="$style.lattice">
              <div v-for="(item, index) in userData" :key="index" :class="$style.latticeItem">
                <img :src="item[data.uc_user.icon_style]" alt="" />
                <p class="mt-4">{{ item.name }}</p>
              </div>
            </div>
          </div>
          <div :class="$style.editoractive" />
        </div>

        <div :class="[$style.ucuser, type === 'uc_system' ? $style.active : null]" @click="onChoose('uc_system')">
          <div v-if="data.uc_system.show_style === 'list'" :class="$style.list">
            <div v-for="(item, index) in systemData" :key="index" :class="$style.listItem">
              <img :src="item[data.uc_system.icon_style]" alt="" />
              <div :class="$style.text">
                <span>{{ item.name }}</span>
                <span :class="$style.btn">
                  <span>{{ item.tip || null }}</span>
                  <i class="ri-arrow-right-s-line" />
                </span>
              </div>
            </div>
          </div>
          <div v-if="data.uc_system.show_style === 'lattice'" :class="$style.boxs">
            <p v-if="data.uc_system.is_show_title" :class="$style.title">系统相关</p>
            <div :class="$style.lattice">
              <div v-for="(item, index) in systemData" :key="index" :class="$style.latticeItem">
                <img :src="item[data.uc_system.icon_style]" alt="" />
                <p class="mt-4">{{ item.name }}</p>
              </div>
            </div>
          </div>
          <div :class="$style.editoractive" />
        </div>
        <div :class="$style.brag">
          <span>运城文旅云</span>
          提供技术支持
        </div>
      </div>

      <div :class="$style.menu">
        <div :class="$style.drop">
          <span />
        </div>
        <div :class="$style.menuBox">
          <div v-for="(item, index) in menu" :key="index" :class="$style.menuItem">
            <img v-if="index !== menu.length - 1" :src="item.default_icon.cover" alt="" />
            <img v-else :src="item.select_icon.cover" alt="" />
            <p v-if="index !== menu.length - 1" class="ellipsis-width">{{ item.name }}</p>
            <p v-else class="ellipsis-width primary">{{ item.name }}</p>
          </div>
        </div>
      </div>
      <div :class="$style.foot">
        <img :src="oss + '/manage/media/assets/homebar.png'" />
      </div>
    </div>
    <!-- </a-affix> -->
    <div :class="$style.right">
      <div v-if="type === 'uc_head'">
        <p :class="$style.title">页面头部</p>
        <a-row>
          <a-col span="6">
            <span class="required">*</span>
            头部样式
          </a-col>
          <a-col span="18">
            <a-radio-group v-model="data.uc_head.style">
              <a-radio value="fillet"> 圆角 </a-radio>
              <a-radio value="card"> 卡片 </a-radio>
              <a-radio value="arc"> 圆弧 </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="6">
            <span class="required">*</span>
            背景图片
          </a-col>
          <a-col span="18">
            <!-- <BjButton class="btn-default" @click="onChooseImg()">
              <i class="ri-upload-line left" />
              选择图片
            </BjButton>
            <div :class="$style.imgbox">
              <img v-if="data.uc_head.bg_image" :src="data.uc_head.bg_image.cover" alt="" />
            </div> -->
            <selectMaterial
              v-if="data.uc_head.bg_image"
              :data="data.uc_head.bg_image"
              :allow="['image']"
              @change="backChange"
            />
            <p class="tip mt-10">图片建议尺寸780px*500px；大小不超过2M；格式支持JPG、PNG。</p>
          </a-col>
        </a-row>
      </div>

      <div v-if="type === 'uc_data'">
        <p :class="$style.title">数据展示</p>
        <a-row>
          <a-col span="6">
            <span class="required">*</span>
            数据样式
          </a-col>
          <a-col span="18">
            <a-radio-group v-model="data.uc_data.style">
              <a-radio value="white"> 白色 </a-radio>
              <a-radio value="deep"> 深纹 </a-radio>
              <a-radio value="shallow"> 浅纹 </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
      </div>

      <div v-if="type === 'uc_user'">
        <p :class="$style.title">用户相关</p>
        <a-row>
          <a-col span="6">
            <span class="required">*</span>
            展示样式
          </a-col>
          <a-col span="18">
            <a-radio-group v-model="data.uc_user.show_style">
              <a-radio value="list"> 列表 </a-radio>
              <a-radio value="lattice"> 宫格 </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row v-if="data.uc_user.show_style === 'lattice'">
          <a-col span="6">
            <span class="required">*</span>
            展示标题
          </a-col>
          <a-col span="18">
            <a-radio-group v-model="data.uc_user.is_show_title">
              <a-radio :value="1"> 展示 </a-radio>
              <a-radio :value="0"> 隐藏 </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="6">
            <span class="required">*</span>
            图标风格
          </a-col>
          <a-col span="18">
            <a-radio-group v-model="data.uc_user.icon_style">
              <a-radio value="line"> 线框 </a-radio>
              <a-radio value="fill"> 填充 </a-radio>
              <a-radio value="color"> 多色 </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
      </div>

      <div v-if="type === 'uc_system'">
        <p :class="$style.title">系统相关</p>
        <a-row>
          <a-col span="6">
            <span class="required">*</span>
            数据样式
          </a-col>
          <a-col span="18">
            <a-radio-group v-model="data.uc_system.show_style">
              <a-radio value="list"> 列表 </a-radio>
              <a-radio value="lattice"> 宫格 </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row v-if="data.uc_system.show_style === 'lattice'">
          <a-col span="6">
            <span class="required">*</span>
            展示标题
          </a-col>
          <a-col span="18">
            <a-radio-group v-model="data.uc_system.is_show_title">
              <a-radio :value="1"> 展示 </a-radio>
              <a-radio :value="0"> 隐藏 </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="6">
            <span class="required">*</span>
            图标风格
          </a-col>
          <a-col span="18">
            <a-radio-group v-model="data.uc_system.icon_style">
              <a-radio value="line"> 线框 </a-radio>
              <a-radio value="fill"> 填充 </a-radio>
              <a-radio value="color"> 多色 </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
      </div>
      <div v-if="type === 'uc_app'" :class="$style.app">
        <p :class="$style.title">应用展示</p>
        <a-row>
          <a-col span="6">
            <span class="required">*</span>
            展示样式
          </a-col>
          <a-col span="18">
            <a-radio-group v-model="data.uc_app.show_style">
              <a-radio value="list"> 列表 </a-radio>
              <a-radio value="lattice"> 宫格 </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="6">
            <span class="required">*</span>
            图标风格
          </a-col>
          <a-col span="18">
            <a-radio-group v-model="data.uc_app.icon_style">
              <a-radio value="line"> 线框 </a-radio>
              <a-radio value="fill"> 填充 </a-radio>
              <a-radio value="color"> 多色 </a-radio>
              <a-radio value="custom"> 自定义 </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="6">
            <span class="required">*</span>
            设置外观
          </a-col>
          <a-col span="18">
            <div :class="$style.appBox">
              <div :class="$style.appTitle">所有菜单</div>
              <div v-for="(item, index) in data.uc_app.menu" :key="item.title" :class="$style.appItem">
                <div :class="$style.appHead">
                  <BjSwitch v-model="item.is_show" />
                  <span :class="$style.appTitle"> {{ item.title }}</span>
                  <span :class="$style.appLabel"
                    >由 {{ item.label }} 提供
                    <i
                      v-if="show[index]"
                      :class="$style.appBtn"
                      class="ri-arrow-up-s-line"
                      @click="onShow(index, false)"
                    />
                    <i v-else :class="$style.appBtn" class="ri-arrow-down-s-line" @click="onShow(index, true)" />
                  </span>
                </div>
                <div v-if="show[index]" :class="$style.appInput">
                  <BjInput v-model="item.title" v-bind="layout" rules="required" label="菜单名称" label-align="left" />
                  <!-- @blur="save" -->
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div :class="$style.foot">
        <BjButton v-permission="'terminal.mobile.uc_config-edit'" type="primary" @click="save()">
          <i class="ri-save-line left" />
          立即保存
        </BjButton>
      </div>
    </div>
    <chooseMaterial :visible.sync="visible.chooseImg" :allow="['image']" @getChoose="getChoose" />
  </div>
</template>

<script>
import _ from 'lodash'

import chooseMaterial from '@/components/chooseMaterial'
import selectMaterial from '@/components/selectMaterial'
import { terminalSettingService } from '@/service'

const service = new terminalSettingService()

export default {
  name: 'Home',
  components: {
    chooseMaterial,
    selectMaterial,
  },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      data: {
        uc_head: {},
        uc_data: {},
        app_menu: null,
        uc_user: {},
        uc_system: {},
      },
      type: 'uc_head',
      visible: {
        chooseImg: false,
      },
      showData: [
        { name: '评论', num: 28 },
        { name: '收藏', num: 52 },
        { name: '关注', num: 13 },
        { name: '历史', num: 5222 },
      ],
      show: [],
      menu: [],
      userData: [
        {
          name: '我的积分',
          color: this.oss + '/front/icon/user/color/user_integral.png',
          fill: this.oss + '/front/icon/user/fill/user_integral.png',
          line: this.oss + '/front/icon/user/line/user_integral.png',
          tip: '1024分',
        },
        {
          name: '我的创作',
          color: this.oss + '/front/icon/user/color/user_works.png',
          fill: this.oss + '/front/icon/user/fill/user_works.png',
          line: this.oss + '/front/icon/user/line/user_works.png',
        },
      ],
      systemData: [
        {
          name: '系统设置',
          color: this.oss + '/front/icon/user/color/system_setting.png',
          fill: this.oss + '/front/icon/user/fill/system_setting.png',
          line: this.oss + '/front/icon/user/line/system_setting.png',
          tip: '1024分',
        },
        {
          name: '关于我们',
          color: this.oss + '/front/icon/user/color/system_about.png',
          fill: this.oss + '/front/icon/user/fill/system_about.png',
          line: this.oss + '/front/icon/user/line/system_about.png',
        },
      ],
    }
  },
  computed: {
    dataStyle() {
      switch (this.data.uc_data.style) {
        case 'white':
          return { background: '#fff', color: '#000' }
        case 'deep':
          return {
            background: 'url(' + this.oss + '/front/media/user/dark.png' + ')',
            color: '#fff',
          }
        case 'shallow':
          return {
            background: 'url(' + this.oss + '/front/media/user/light.png' + ')',
            color: '#000',
          }
      }
      return {}
    },
    dataTipStyle() {
      switch (this.data.uc_data.style) {
        case 'white':
          return { color: '#5c5c5c' }
        case 'deep':
          return {
            color: '#bbb',
          }
        case 'shallow':
          return {
            color: '#5c5c5c',
          }
      }
      return {}
    },
  },
  // watch: {
  //   data: {
  //     handler() {
  //       this.save()
  //     },
  //     deep: true,
  //   },
  // },
  created() {
    this.getInfo()
    this.getMenu()
  },
  methods: {
    async getInfo() {
      try {
        const { data } = await service.getUser()
        this.data = data
        data.uc_app.menu.map(item => {
          if (item) {
            this.show.push(false)
          }
        })
      } catch (e) {}
    },
    async getMenu() {
      try {
        const { data } = await service.getInfoGlobal()
        this.menu = data.main_nav
      } catch (e) {}
    },
    async save() {
      try {
        await service.saveUser({
          type: this.type,
          uc_head: JSON.stringify(this.data.uc_head),
          uc_data: JSON.stringify(this.data.uc_data),
          uc_user: JSON.stringify(this.data.uc_user),
          uc_system: JSON.stringify(this.data.uc_system),
          uc_app: JSON.stringify(this.data.uc_app),
        })
        // this.$message.success('保存成功')
      } catch (e) {}
    },
    onChoose(_type) {
      this.type = _type
    },
    getChoose(data) {
      this.data.uc_head.bg_image = data[0]
    },
    backChange(data) {
      this.data.uc_head.bg_image = data[0]
    },
    onShow(index, type) {
      this.show[index] = type
      this.show = _.cloneDeep(this.show)
    },
  },
}
</script>

<style lang="less" module>
.home {
  display: flex;
  flex-direction: row;

  .left {
    position: relative;
    width: 400px;
    // height: calc(100vh - 152px);
    // max-height: 750px;
    margin-right: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);

    &-flow {
      position: relative;
      width: 390px;
      // height: 850px;
      margin: 0 auto;
      padding-top: 2px;
      // overflow-x: hidden;
    }

    .head {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      width: 100%;
      height: 44px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .foot {
      position: absolute;
      bottom: 0;
      background: #fff;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .brag {
      height: 140px;
      margin-top: 30px;
      color: #5c5c5c;
      font-size: 12px;
      text-align: center;

      span {
        color: @primary-color;
      }
    }

    .box {
      width: 100%;
      height: 60px;
      cursor: pointer;
    }
  }

  .left::-webkit-scrollbar {
    display: none;
  }

  .right {
    position: relative;
    flex: 1;
    // height: calc(100vh - 152px);
    padding: 20px;
    overflow-y: auto;
    color: #000;
    background: #fff;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .title {
      font-size: 16px;
    }

    .stitle {
      font-size: 14px;
    }

    :global {
      .ant-row {
        margin: 20px 0 0 0;
      }

      .ant-radio-wrapper {
        color: #000;
      }
    }

    .foot {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 10px;
      box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);
    }

    .imgbox {
      position: relative;
      width: 100px;
      height: 100px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);
      }
    }

    .app {
      .app-box {
        padding: 20px;
        background-color: #fafafa;
      }

      .app-item {
        padding: 14px;
        background-color: #fff;
      }

      .app-head {
        width: 100%;
        margin-bottom: 14px;
      }

      .app-title {
        color: #000;
        font-size: 14px;
      }

      .app-label {
        float: right;
      }

      .app-input {
        margin-left: 47px;
        border-top: 1px solid #eee;

        :global {
          .ant-row {
            margin-top: 14px;
          }
        }
      }

      .app-btn {
        font-size: 20px;
      }
    }
  }

  .editoractive {
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    z-index: 11;
    display: none;
    border: 2px solid @primary-color;
    pointer-events: none;
  }

  .active {
    .editoractive {
      display: block;
    }
  }

  .uchead {
    position: relative;
    min-height: 100px;
    border-radius: 6px;

    .fillet {
      height: 254px;
      padding-top: 44px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 0 0 20px 20px;

      .title {
        position: relative;
        height: 44px;
        color: #fff;
        font-size: 16px;
        line-height: 44px;
        text-align: center;

        .i {
          position: absolute;
          top: 10px;
          left: 20px;
          color: #fff;
          font-size: 24px;
        }
      }

      .info {
        display: flex;
        margin-top: 72px;
        padding: 0 20px;

        img {
          width: 70px;
          height: 70px;
        }

        .text {
          padding: 10px 0 0 15px;
          color: #fff;
          font-size: 12px;

          p:first-child {
            margin-bottom: 5px;
            font-size: 20px;
          }
        }
      }
    }

    .card {
      height: 254px;
      padding-top: 44px;

      .title {
        position: relative;
        height: 44px;
        color: #000;
        font-size: 16px;
        line-height: 44px;
        text-align: center;

        .i {
          position: absolute;
          top: 10px;
          left: 20px;
          color: #000;
          font-size: 24px;
        }
      }

      .content {
        width: 350px;
        height: 150px;
        margin: 15px auto 0;
        padding-top: 40px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 6px;

        .info {
          display: flex;
          padding: 0 20px;

          img {
            width: 70px;
            height: 70px;
          }

          .text {
            padding: 10px 0 0 15px;
            color: #fff;
            font-size: 12px;

            p:first-child {
              margin-bottom: 5px;
              font-size: 20px;
            }
          }
        }
      }
    }

    .arc {
      height: 300px;

      .title {
        position: absolute;
        bottom: 80px;
        left: 50%;
        width: 390px;
        height: 44px;
        max-height: 190px;
        color: #fff;
        font-size: 16px;
        line-height: 44px;
        text-align: center;
        transform: translate(-50%, 0%);

        .i {
          position: absolute;
          top: 10px;
          left: 20px;
          color: #fff;
          font-size: 24px;
        }
      }

      .radius {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 250%;
        height: 200%;
        overflow: hidden;
        background: #fff;
        border-radius: 100%;
        transform: translate(-50%, -560px);
        /* stylelint-disable-next-line no-descending-specificity */
        img {
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 390px;
          max-height: 190px;
          transform: translate(-50%, 0%);
          object-fit: cover;
        }
      }
      /* stylelint-disable-next-line no-descending-specificity */
      .info {
        position: relative;
        top: 166px;
        text-align: center;
        /* stylelint-disable-next-line no-descending-specificity */
        img {
          width: 70px;
          height: 70px;
        }
        /* stylelint-disable-next-line no-descending-specificity */
        .text {
          color: #5c5c5c;
          font-size: 12px;
          /* stylelint-disable-next-line no-descending-specificity */
          p:first-child {
            margin-bottom: 5px;
            color: #000;
            font-size: 20px;
          }
        }
      }
    }
  }

  .ucdata {
    position: relative;
    height: 80px;
    margin-top: 20px;
    border-radius: 6px;

    .box {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 350px;
      height: 80px;
      margin: 0 auto;
      border-radius: 6px;

      .item {
        width: 70px;
        font-size: 12px;
        text-align: center;
        /* stylelint-disable-next-line no-descending-specificity */
        p:first-child {
          margin-top: 16px;
          margin-bottom: 5px;
          font-size: 20px;
        }
      }
    }
  }

  .ucuser {
    position: relative;
    margin-top: 20px;
    padding: 0 14px;

    /* stylelint-disable-next-line no-descending-specificity */
    .boxs {
      width: 350px;
      margin: 0 auto;
      background: #fff;
      border-radius: 6px;
    }

    .list {
      width: 350px;
      margin: 0 auto;
      background: #fff;
      border-radius: 6px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.02);

      .list-item {
        display: flex;
        align-items: center;
        height: 48px;
        padding: 0 10px 0 14px;
        color: #000;
        font-size: 14px;
        line-height: 48px;
        /* stylelint-disable-next-line no-descending-specificity */
        img {
          width: 20px;
          height: 20px;
        }
        /* stylelint-disable-next-line no-descending-specificity */
        .text {
          flex: 1;
          padding-top: 6px;
          padding-left: 14px;
          border-bottom: 1px solid #fafafa;

          .btn {
            float: right;
            color: #5c5c5c;
            font-size: 12px;

            i {
              color: #bbb;
              font-size: 20px;
            }

            span {
              position: relative;
              top: -3px;
            }
          }
        }
      }
    }

    .lattice {
      display: flex;
      padding-top: 14px;

      &-title {
        font-size: 16px;
      }

      &-item {
        width: 80px;
        text-align: center;
        /* stylelint-disable-next-line no-descending-specificity */
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    /* stylelint-disable-next-line no-descending-specificity */
    .title {
      height: 36px;
      margin-bottom: 6px;
      padding-left: 14px;
      font-size: 16px;
      line-height: 50px;
    }
  }
}

.menu {
  position: absolute;
  bottom: 34px;
  width: 100%;
  height: 70px;
  background: #fff;
  border-radius: 20px 20px 0 0;

  .drop {
    height: 25px;
    line-height: 10px;
    text-align: center;
    /* stylelint-disable-next-line no-descending-specificity */
    span {
      display: inline-block;
      width: 40px;
      height: 4px;
      background: #eee;
      border-radius: 2px;
    }
  }

  &-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  &-item {
    width: 70px;
    text-align: center;
    /* stylelint-disable-next-line no-descending-specificity */
    img {
      width: 24px;
      height: 24px;
      object-fit: cover;
    }
    /* stylelint-disable-next-line no-descending-specificity */
    p {
      margin: 2px 0 0 0;
    }
  }
}
@media (min-height: 902px) {
  .left {
    width: 394px !important;
  }
}
</style>
